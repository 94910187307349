.rc-color-picker {
  position: absolute;
  z-index: 1;
  display: block;
  width: max-content;
  min-width: 258px;
  visibility: visible;
}
.rc-color-picker-hidden {
  display: none;
}
.rc-color-picker-panel {
  display: flex;
  flex-direction: column;
  width: 258px;
  padding: 12px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.rc-color-picker-panel-disabled {
  cursor: not-allowed;
}
.rc-color-picker-select {
  margin-bottom: 12px;
}
.rc-color-picker-select .rc-color-picker-palette {
  min-height: 160px;
  overflow: hidden;
  border-radius: 4px;
}
.rc-color-picker-select .rc-color-picker-palette > .rc-color-picker-gradient {
  border-top-left-radius: 5px;
}
.rc-color-picker-saturation {
  position: absolute;
  border-radius: inherit;
  inset: 0px;
}
.rc-color-picker-handler {
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border: 2px solid #fff;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.06);
}
.rc-color-picker-handler-sm {
  width: 12px;
  height: 12px;
}
.rc-color-picker-slider {
  width: 100%;
  margin-bottom: 12px;
}
.rc-color-picker-slider .rc-color-picker-palette {
  height: 8px;
}
.rc-color-picker-slider .rc-color-picker-gradient {
  border-radius: 4px;
}
.rc-color-picker-slider-alpha {
  background: url('./alpha.svg');
}
.rc-color-picker-color-block {
  position: relative;
  width: 28px;
  height: 28px;
  margin-left: 8px;
  overflow: hidden;
  background-image: url('./alpha.svg');
  border-radius: 4px;
}
.rc-color-picker-color-block-inner {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}
.rc-color-picker-slider-container {
  display: flex;
}
.rc-color-picker-slider-container .rc-color-picker-slider-group {
  flex: 1;
}
.rc-color-picker-slider-container .rc-color-picker-slider-group-disabled-alpha {
  display: flex;
  align-items: center;
}
.rc-color-picker-slider-container .rc-color-picker-slider-group-disabled-alpha .rc-color-picker-slider {
  margin-bottom: 0;
}
